import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import { useAuth } from "auth-utils";

function App() {
  const [logged] = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        {!logged && <Auth />}
        {logged && <Admin/>}
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
