import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableRow from '@material-ui/core/TableRow';
import { userReducer, userData } from "auth-utils";
import { authFetch } from "auth-utils";
import { API_BASE_URI, sendTestCase } from "auth-utils";
import colors from 'tailwindcss/colors';
import testDeviceDropdown from "../Dropdowns/TestDeviceDropdown";
import TestDeviceDropdown from "../Dropdowns/TestDeviceDropdown";



var ID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export default function CardTable({ color }) {
  const [user, dispatch] = useReducer(userReducer, userData);
  const [tests, setTests] = useState([]);

  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const history = useHistory();
  const [testCase, setTestCase] = useState(/* activeTest != null ? activeTest.code : languages["shell"] */);
  const [codeType, setCodeType] = useState(/* activeTest != null ? activeTest.ext : "shell" */);

  const [attrValue, setAttrValue] = useState(/* activeTest != null ? activeTest.attributes : "First-name Surename" */);
  const [tesResult, setTesResult] = useState({});

 const [showID,setShow]=useState(0)
  const [cCommand, setCCommand] = useState("");
  const [cFileName, setcFileName] = useState("");
  
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  
  const runAllTests = (device) => {
    const deviceTests = (tests[device?.id.id] || []).map(t => {
      delete t.result;
      return t;
    });

    setTests({ ...tests, [device?.id.id]: deviceTests });
    setLoading(true);

    const runTest = (index) => {
      if (index === 0) {
        setTests({
          ...tests, [device?.id.id]: deviceTests.map(t => {
            t.value.result = null;
            return t;
          })
        });
      }

      if (index === deviceTests.length) {
        setLoading(false);
        setStatus(Object.values(deviceTests).find(res => res.result?.status === "ERROR") ? "ERROR" : "SUCCESS");
        return;
      }

      const test = deviceTests[index];
      sendTestCase(device, test.value, async (result, device, test) => {
        setTests({
          ...tests, [device?.id.id]: deviceTests.map(t => {
            if (t.value?.id === test?.id) {
              t.value.result = result;
            }

            return t;
          })
        });

        await authFetch(
          `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${typeof device.id === "string" ? device.id : device.id.id}/attributes/SERVER_SCOPE`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              [test.id]: {
                ...test,
                result
              }
            })
          }
        );

        runTest(index + 1);
      });
    };

    runTest(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await authFetch(
          `${API_BASE_URI}/api/customer/${user?.customerId?.id}/deviceInfos?pageSize=10&page=0`
        );

        const devicesAttributes = await Promise.all(
          result.data.map(device =>
            authFetch(
              `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${device.id.id}/values/attributes/SERVER_SCOPE`
            )
          )

        );
        console.log(devicesAttributes)
        const testCases = devicesAttributes.reduce((acc, attributes, index) => {
          const tests = attributes.filter(attribute => typeof attribute.value === "object" && attribute.value.test_case != null);
          acc[result.data[index].id.id] = tests;
          console.log(acc)
          return acc;
        }, {});

        setTests(testCases);
        setDevices(result.data);
      } catch (e) {
        setTests({});
        setDevices([]);
      }
    };

    fetchData();
  }, []);
  const classes = useStyles();


    function setShowID(id) {
        if(showID==0){
            setShow(id)
        }else{
            setShow(0)
        }
        return undefined;
    }

    return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Devices Test Cases
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <TableRow >
                
                <th
                
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <div style={{width: 200 }}>Name</div>
                  
                </th>
               
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <div style={{width: 200 }}>Customer</div>
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <div style={{width: 100 }}>Status</div>
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ><div style={{width: 200 }}></div></th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ><div style={{width: 200 }}></div></th>
                </TableRow>
              </tr>
            </thead>
            <tbody>
              {
                
                devices?.map(device => {

                  return (
                    <>

                    <TableRow>
                      <tr >
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                            <IconButton onClick={() =>
                                setShowID(device.id.id)
                                }>
                              <ExpandMoreIcon fontSize="Medium" />
                            </IconButton>
                          <div style={{width: 90 }}>{device.name}</div>

                        </th>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div style={{width: 250 }}>{device.customerTitle}</div>
                        </th>
                        <td className="border-t-0 px-6 align-left border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div style={{width: 100 }}>{status === "SUCCESS" ? <i style={{ color: colors.green[600] }} className="fas fa-circle mr-2"></i> : <i className="fas fa-circle text-red-500 mr-2"></i>}</div>
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <button
                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            //onClick={() => WriteTests(device)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`/admin/devices/${device?.id.id}`);
                            }}
                          > <div style={{width: 100 }}>Write Test</div>
                          </button>
                          
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <button
                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => runAllTests(device)}
                          >
                            <div style={{width: 100 }}>{loading === true ? 'Loading...' : 'Run all'}</div>
                          </button>
                        </td>  
                      </tr>

                      </TableRow>
                      <TableRow key={device.id.id}>
                        <TestDeviceDropdown

                            isShow={device.id.id==showID}
                            deviceID={device.id.id}
                            theColor={color}
                            theUser={user}
                            isLoading={loading}
                            classes={classes}
                            device={device}
                            testCase={tests}
                            testResult={tesResult}/>
                      </TableRow>




                    </>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
