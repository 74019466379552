import { createAuthProvider } from 'react-token-auth';
import colors from 'tailwindcss/colors';

export const API_BASE_URI = 'https://cpts-admin.idial.institute';

export let userData = {};

const customFetch = (input, init) => {
    init.headers['X-Authorization'] = init.headers.Authorization;
    return fetch(input, init).then(async response => {
        if (response.status === 409) {
            return {
                errors: [{
                    code: "UNABLE_TO_CONNECT",
                    message: 'Unable to connect'
                }],
            };
        }

        if (response.status === 401) {
            throw logout();
        }

        try {
            const result = await response.json();
            return result;
        } catch (e) {
            return {};
        }
    }).then(result => result);
};

export const [useAuth, authFetch, login, logout] =
    createAuthProvider({
        accessTokenKey: 'token',
        customFetch,
        onUpdateToken: (token) => fetch(`${API_BASE_URI}/api/auth/token`, {
            method: 'POST',
            headers: {
                'X-Authorization': 'Bearer' + token.token
            },
            body: JSON.stringify({
                refresh_token: token.refreshToken
            })
        }).then(r => r.json()).catch(e => null)
    });

export const getUser = (dispatch) => {
    authFetch(`${API_BASE_URI}/api/auth/user`)
        .then(user => {
            dispatch({ type: SET_USER, payload: user });
        })
        .catch(e => {
            dispatch({ type: SET_USER, payload: null });
        })
}

export const SET_USER = 'SET_USER';

export function userReducer(state = userData, action) {
    switch (action.type) {
        case SET_USER:
            userData = JSON.parse(JSON.stringify(action.payload));
            return userData;
        default:
            return userData
    }
}

export async function communicateDevice(devideId, type, deviceBody) {
    return await authFetch(
        `${API_BASE_URI}/api/plugins/rpc/${type}/${devideId}`,
        {
            method: "POST",
            body: JSON.stringify(deviceBody)
        }
    );
}

export async function sendTestCase(device, test, setTesResult) {
    try {
        const result = await communicateDevice(typeof device.id === "string" ? device.id : device.id.id, 'twoway', {
            "method": "runCode",
            "params": test
        });

        if (Array.isArray(result.errors) && result.errors.find(error => error.code === "UNABLE_TO_CONNECT")) {
            return setTesResult({
                message: "Unable to connect to device!",
                color: colors.red['600'],
                status: 'ERROR',
                id: test.id
            }, device, test);
        } else {
            let testRes;

            if (result.err != "") {
                testRes = {
                    ...result,
                    message: `Test failed. Error: ${result.err}`,
                    color: colors.red['600'],
                    status: 'ERROR'
                };
            } else {
                testRes = {
                    ...result,
                    message: `Test succeeded! ${result.out}`,
                    color: colors.green['600'],
                    status: 'SUCCESS'
                };
            }

            if (result.err != "") {
                return setTesResult(testRes, device, test);
            } else {
                return setTesResult(testRes, device, test);
            }
        }
    } catch (e) {
        return setTesResult({
            message: "Unable to connect to device!",
            color: colors.red['600'],
            status: 'ERROR',
            id: test.id
        }, device, test);
    }
}