import React, { useEffect, useReducer, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Device from "views/admin/Device";
import { userReducer } from "auth-utils";
import { getUser } from "auth-utils";
import { userData } from "auth-utils";
import { useAuth } from "auth-utils";

const isNotValidUser = (user) => {
  return !user || Object.keys(user).length === 0;
}

function Admin() {
  const [user, dispatch] = useReducer(userReducer, userData);
  const [logged] = useAuth();

  useEffect(() => {
    if (isNotValidUser(user)) {
      getUser(dispatch);
    }
  }, []);

  return (
    <>
      {
        !isNotValidUser(user) ? (
          <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
              <AdminNavbar />
              {/* Header */}
              <HeaderStats />
              <div className="px-4 md:px-10 mx-auto w-full -m-24">
                <Switch>
                  <Route path="/admin/dashboard" exact component={Dashboard} />
                  <Route path="/admin/settings" exact component={Settings} />
                  <Route path="/admin/devices" exact component={Tables} />
                  <Route path="/admin/devices/:id" exact component={Device} />
                  <Route path='*' exact={true}>
                    <Redirect to='/admin/dashboard' />
                  </Route>
                </Switch>
                <FooterAdmin />
              </div>
            </div>
          </>
        ) : (
            <>
              {
                !logged ? <Redirect to="/auth" /> : <span>Loading...</span>
              }
            </>
          )
      }
    </>
  );
}

export default Admin;