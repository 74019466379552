import { useEffect, useReducer, useRef, useState } from "react";
import uniqueFilename from "unique-filename";
import {useParams} from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import Editor from "@monaco-editor/react";
import { userReducer, communicateDevice } from "auth-utils";
import { userData } from "auth-utils";
import { authFetch, SET_USER } from "auth-utils";
import { API_BASE_URI } from "auth-utils";
import Select from 'react-select'
import { c } from "./c";
import { sendTestCase } from 'auth-utils';




const language = [
    { value: 'c', label: 'c' },
    { value: 'shell', label: 'shell' },
    { value: 'python', label: 'python' },
    { value: 'cpp', label: 'cpp' },
    { value: 'json', label: 'json'}
]

const options =[
    { value: 'spi_test', label: 'spi_test' },
    { value: 'uart_test', label: 'uart_test'}
]
const test_Case = [
    {value: 'LED1_ON', label: 'LED1_ON'},
    {value: 'LED1_OFF', label: 'LED1_OFF'},
    {value: 'LED2_ON', label: 'LED2_ON'},
    {value: 'LED2_OFF', label: 'LED2_OFF'},
    {value: 'END', label: 'END'}
]

const languages = {
    shell: `#!/bin/bash
echo "Hello world"

# example of using arguments to a script
echo "My first name is $1"
echo "My surname is $2"
echo "Total number of arguments is $#"`,
    python: `
class Monkey:
    # Bananas the monkey can eat.
    capacity = 10
    def eat(self, n):
        """Make the monkey eat n bananas!"""
        self.capacity -= n * 10

    def feeding_frenzy(self):
        self.eat(9.25)
        return "Yum yum"

monkey = Monkey()
print(monkey.feeding_frenzy())
`,
    c: c,
    cpp: `#include <iostream>

int main() {
    std::cout << "Hello World!";
    return 0;
}`
,
    json:`
    {"test_suite": 
	[{"ts_name": "spi_test", 
		"ts_tests_results": [1, 1, 1, 1, -1], 
			"test_cases": 
			[{"failure": "LED2_ON", 
				"name": "LED1_ON", 
					"success": "LED1_OFF"}, 
			{"failure": "END", 
				"name": "LED1_OFF", 
					"success": "LED2_ON"}, 
			{"failure": "END", 
				"name": "LED2_ON", 
				"success": "LED2_OFF"}, 
			{"failure": "END", 
				"name": "LED2_OFF", 
					"success": "END"}, 
			{"name": "END"}], 
			"device_name": "linux_vm", 
			"ts_tests": ["LED1_ON", "LED1_OFF", "LED2_ON", "LED2_OFF", "END"], 
			"ts_result": 1}, 
			
	{"ts_name": "uart_test", 
		"ts_tests_results": [1, 1, 1, 1, -1], 
			"test_cases": 
			[{"failure": "LED2_ON", 
				"name": "LED1_ON", 
					"success": "LED1_OFF"}, 
			{"failure": "END", 
				"name": "LED1_OFF", 
					"success": "LED2_ON"}, 
			{"failure": "END", 
				"name": "LED2_ON", 
					"success": "LED2_OFF"}, 
			{"failure": "END", 
				"name": "LED2_OFF", 
					"success": "END"}, 
			{"name": "END"}], 
			"device_name": "linux_vm", 
			"ts_tests": ["LED1_ON", "LED1_OFF", "LED2_ON", "LED2_OFF", "END"], 
			"ts_result": 1}]}`

};

const extentions = {
    shell: 'sh',
    python: 'py',
    c: 'c',
    cpp: 'cpp',
    json: 'json'
}

var ID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export default function Device() {
    const [user, dispatch] = useReducer(userReducer, userData);
    const { id: tId } = useParams();
    const idParts = tId.split("-test-");
    const id = idParts[0];
    const testId = idParts[1] === "undefined" ? null : idParts[1];

    const editorRef = useRef(null);
    const monacoRef = useRef(null);
    
    const [device, setDevice] = useState({});
    const [testCase, setTestCase] = useState(/* activeTest != null ? activeTest.code : languages["shell"] */);
    const [codeType, setCodeType] = useState(/* activeTest != null ? activeTest.ext : "shell" */);

    const [attrValue, setAttrValue] = useState(/* activeTest != null ? activeTest.attributes : "First-name Surename" */);
    const [tesResult, setTesResult] = useState({});
    const [loading, setLoading] = useState(true);

    const [cCommand, setCCommand] = useState("");
    const [cFileName, setcFileName] = useState("");
    
    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        monacoRef.current = monaco;
    }
    
    async function saveTestCase() {
        const newId = testId ?? ID();
        await authFetch(
            `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${id}/attributes/SERVER_SCOPE`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    [newId]: {
                        "ext": codeType,
                        "filename": cFileName,
                        "attributes": attrValue,
                        "code": testCase,
                        "id": newId,
                        "command": !!cCommand ? cCommand : "echo 'c command is missing! Maybe gcc?';",
                        "result": tesResult,
                        "test_case": true
                    }
                })
            }
        );

        dispatch({ type: SET_USER, payload: userData });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await authFetch(
                    `${API_BASE_URI}/api/device/info/${id}`
                );

                setDevice(result);
            } catch (e) {
                setDevice(null);
            }
        };

        
        const fetchTest = async () => {
            const afterFinish = (activeTest) => {
                if (activeTest == null || activeTest.value == null) {
                    const fileName = `${uniqueFilename("test-cases/", "test")}.${extentions["shell"]}`;
                    setcFileName(fileName);
                    setTestCase(languages["shell"]);
                    setCodeType("shell");
                    setAttrValue("First-name Surename");
                    
                } else if (activeTest == null || activeTest.value == null) {
                    const fileName = `${uniqueFilename("test-cases/", "test")}.${extentions["json"]}`;
                    setcFileName(fileName);
                    setTestCase(languages["json"]);
                    setCodeType("json");
                    setAttrValue("First-name Surename");
                    
                 } else {
                            const fileName = activeTest.value.filename || `${uniqueFilename("test-cases/", "test")}.${extentions[activeTest.value.ext]}`;

                            let commandName = "";
                            if (activeTest.value.ext === "c") {
                                commandName = `gcc ./${fileName} -o ./${fileName.replace('.c', '.out')} && ./${fileName.replace('.c', '.out')}`;
                            } else if (activeTest.value.ext === "cpp") {
                                commandName = `g++ ./${fileName} -o ./${fileName.replace('.cpp', '.out')} && ./${fileName.replace('.cpp', '.out')}`;
                            }

                            setcFileName(fileName);
                            setTestCase(activeTest.value.code);
                            setCodeType(activeTest.value.ext);
                            setAttrValue(activeTest.value.attributes);
                            setCCommand(commandName);
                            
                }

                setLoading(false);
            };

            if (testId == null) {
                afterFinish();
            } else {
                try {
                    const attributes = await authFetch(
                        `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${id}/values/attributes/SERVER_SCOPE`
                    );
    
                    afterFinish(attributes.find(attributes => attributes.key === testId));
                } catch (e) {
                    afterFinish();
                }
            }
        };


        fetchData();
        fetchTest();
    }, []);
     
    return (
        
        <>
        
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <h6 className="text-blueGray-700 text-xl font-bold">{device?.name}</h6>
                        <div style={{ width: 200 }}>
                            <Select
                                defaultValue={language[1]}
                                value={language.find(option => option.value === codeType)}
                                options={language}
                                onChange={(value, action) => {
                                    const fName = `${uniqueFilename("test-cases/", "test")}.${extentions[value.value]}`;
                                    setcFileName(fName);
                                    setCodeType(value.value);
                                    setTestCase(languages[value.value]);
                                    

                                    if (value.value === "c") {
                                        setCCommand(`gcc ./${fName} -o ./${fName.replace('.c', '.out')} && ./${fName.replace('.c', '.out')}`);
                                    } else if (value.value === "cpp") {
                                        setCCommand(`g++ ./${fName} -o ./${fName.replace('.cpp', '.out')} && ./${fName.replace('.cpp', '.out')}`);
                                    }

                                    const model = monacoRef.current.editor.createModel(
                                        languages[value.value],
                                        value.value, // language
                                        monacoRef.current.Uri.file(uniqueFilename("test-cases/", "test")) // uri
                                    );

                                    editorRef.current.setModel(model);
                                }} />
                        </div>
                        {codeType === "shell" ? <div>
                                    
                        </div> : null}
                        {codeType === "c" || codeType === "cpp" ? <div>
                            
                        </div> : null}
                        {codeType === "json" ? <div>
                       
                           
                        </div> : null}
                        <div>
                            <input
                                type="text"
                                placeholder="C Filename"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={(value) => setcFileName(value.target.value)}
                                value={cFileName}
                            />
                        </div>
                        <div>
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                onClick={async () => {
                                    await saveTestCase();
                                }}
                                type="button"
                            >
                                Save Test
                            </button>
                                    
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                onClick={async () => {
                                    await sendTestCase(device, {
                                        "ext": codeType,
                                        "filename": cFileName,
                                        "attributes": attrValue,
                                        "code": testCase,
                                        "id": ID(),
                                        "command": !!cCommand ? cCommand : "echo 'c command is missing! Maybe gcc?';",
                                        "result": tesResult
                                    }, setTesResult);
                                }}
                                type="button"
                            >
                                Send Test
                            </button>
                        </div>
                    </div>
                </div>

                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    {loading === true ? "Loading..." : <Editor
                        height="400px"
                        defaultLanguage={codeType}
                        defaultValue={testCase}
                        onChange={(value) => setTestCase(value)}
                        onMount={handleEditorDidMount}
                    />}
                </div>

                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full max-w-full flex-grow flex-1">
                                <h2 className="text-blueGray-700 text-xl font-semibold">
                                    Test Results:
                                 </h2>

                                <br />
                                <h6 className="uppercase text-black-400 mb-1 text-xs font-semibold" style={{ color: tesResult.color }}>
                                    {tesResult.message || 'Nothing yet...'}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
