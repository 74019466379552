import React from "react";

// components
import CardPageVisits from "components/Cards/CardPageVisits.js";
import {display} from "tailwindcss/lib/plugins";

export default function Dashboard() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 xl:mb-0 px-4">
        </div>


      </div>
        <CardPageVisits style={{display: 'block'}}/>
    </>
  );
}
