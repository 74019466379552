import React, { useReducer } from "react";
import { createPopper } from "@popperjs/core";
import { userReducer } from "auth-utils";
import { userData } from "auth-utils";
import { logout, authFetch } from "auth-utils";
import { API_BASE_URI } from "auth-utils";

const UserDropdown = () => {
  // dropdown props
  const [user, dispatch] = useReducer(userReducer, userData);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span style={{     color: "#0084c7",
    fontSize: "24px"}} className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            { user.firstName[0] }
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href="javascript:void(0)"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {
            authFetch(`${API_BASE_URI}/api/auth/logout`, { method: "post" }).then(res => {
              logout();
            });
          }}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
