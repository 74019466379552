import React, {useEffect, useReducer, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {API_BASE_URI, authFetch, sendTestCase} from "../../auth-utils";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import colors from 'tailwindcss/colors';
import { makeStyles } from '@material-ui/core/styles';
import { userReducer, userData } from "auth-utils";
import {useHistory} from "react-router-dom";



function TestDeviceDropdown (props){
    const [show,setShow]=useState(false);
    const [devices, setDevices] = useState([]);
    const [tests, setTests] = useState([]);
    const [tesResult, setTesResult] = useState({});
    const history = useHistory();


    useEffect(()=>{
        /*
        const fetchData = async () => {

            try {
                const result = await authFetch(
                    `${API_BASE_URI}/api/customer/${props.user?.customerId?.id}/deviceInfos?pageSize=10&page=0`
                );
                const devicesAttributes = await Promise.all(
                    result.data.map(device =>
                        authFetch(
                            `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${props.deviceID}/values/attributes/SERVER_SCOPE`
                        )
                    )

                );
                console.log(devicesAttributes)
                const testCases = devicesAttributes.reduce((acc, attributes, index) => {
                    const tests = attributes.filter(attribute => typeof attribute.value === "object" && attribute.value.test_case != null);
                    acc[result.data[index].id.id] = tests;
                    console.log(acc)
                    return acc;
                }, {});

                setTests(testCases);
                setDevices(result.data);
            }catch (e) {
                setTests({});
                setDevices([]);
            }
        }
        fetchData();
        */
        setTests(props.testCase)
        setShow(props.isShow)
        console.log(show
        )
    },[props.isShow])





    return(
        <div>
        {show?
        <tr key={props.key}>

             <h1>
                <tr>

                    <th>
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>

                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (props.theColor === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Test Name
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (props.theColor === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Code Type
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (props.theColor === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Last Update
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (props.theColor === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Test Result
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (props.theColor === "light"
                                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                            >
                                Actions
                            </th>

                            </thead>
                            <tbody>
                            {
                                tests[props.deviceID]?.map((test, index) => {
                                        return (

                                            <tr key={props.deviceID + "" + index}>
                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                                    <div style={{
                                                        "white-space": "wrap",
                                                        overflow: "hidden",
                                                        "text-overflow": "ellipsis",
                                                        "maxWidth": "200px"
                                                    }}>{test.value?.filename}</div>

                                                </th>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <div style={{
                                                        "white-space": "wrap",
                                                        overflow: "hidden",
                                                        "text-overflow": "ellipsis",
                                                        "maxWidth": "100px"
                                                    }}>{test.value?.ext}</div>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <div style={{
                                                        "white-space": "wrap",
                                                        overflow: "hidden",
                                                        "text-overflow": "ellipsis",
                                                        "maxWidth": "200px"
                                                    }}>{new Date(test.lastUpdateTs).toUTCString()}</div>
                                                </td>
                                                <td style={{ color: test.value?.result?.color }} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <div style={{
                                                        "white-space": "wrap",
                                                        overflow: "hidden",
                                                        "text-overflow": "ellipsis",
                                                        "maxWidth": "300px"
                                                    }}>{(props.loading === true && test.value?.result == null ? "Running..." : (test.value?.result?.message != null ? test.value?.result?.message : "No result yet"))}</div>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">


                                                    <IconButton aria-label="send" className={props.classes.margin}
                                                                onClick={async () => {
                                                                    await sendTestCase(props.device, test, setTesResult);
                                                                }}>
                                                        <SendIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton aria-label="edit" className={props.classes.margin}

                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(`/admin/devices/${props.device?.id.id}-test-${test.value.id}`);
                                                                }}>
                                                        <EditIcon fontSize="small"/>

                                                    </IconButton>
                                                    <IconButton aria-label="delete" className={props.classes.margin}>

                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </th>
                </tr>
            </h1>
        </tr>: console.log("no test") }</div>
    )

};
export default TestDeviceDropdown;