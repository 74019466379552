import React, {useEffect, useReducer, useState} from "react";


import {API_BASE_URI, authFetch, userData, userReducer} from "../../auth-utils";
import colors from "tailwindcss/colors";
import {justifyContent, textAlign} from "tailwindcss/lib/plugins";

// components

export default function CardPageVisits() {

    const [user, dispatch] = useReducer(userReducer, userData);
    const [tests, setTests] = useState([]);

    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [tesResult, setTesResult] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await authFetch(
                    `${API_BASE_URI}/api/customer/${user?.customerId?.id}/deviceInfos?pageSize=10&page=0`
                );

                const devicesAttributes = await Promise.all(
                    result.data.map(device =>
                        authFetch(
                            `${API_BASE_URI}/api/plugins/telemetry/DEVICE/${device.id.id}/values/attributes/SERVER_SCOPE`
                        )
                    )

                );
              //  console.log(devicesAttributes)
                const testCases = devicesAttributes.reduce((acc, attributes, index) => {
                    const tests = attributes.filter(attribute => typeof attribute.value === "object" && attribute.value.test_case != null);
                    acc[result.data[index].id.id] = tests;
                  //  console.log(acc)
                    return acc;
                }, {});

                setTests(testCases);
                console.log(tests)
                setDevices(result.data);
            } catch (e) {
                setTests({});
                setDevices([]);
            }
        };

        fetchData();
    }, []);

  return (


      <div className="relative  min-w-0 break-words w-full mb-1 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center">

                  <table >
                      <thead>
                      <tr>
                          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Device Name
                          </th>

                          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              test Name
                          </th>
                          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Last Update
                          </th>
                          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Status
                          </th>
                          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              TEST RESULT
                          </th>
                      </tr>
                      </thead>

                      <tbody>
                      {
                          devices.map((device)=>{
                              return(
                              tests[device.id.id]?.map((test)=> (

                                  <tr style={{ justifyContent:"space-between", textAlign:"start"}}>
                                      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{device.name}</td>
                                      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{test.value.filename}</td>
                                      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{new Date(test.lastUpdateTs).toUTCString()}</td>
                                      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{status === "SUCCESS" ?
                                          <i style={{color: colors.green[600]}} className="fas fa-circle mr-2"></i> :
                                          <i className="fas fa-circle text-red-500 mr-2"></i>}</td>
                                      <td >{test.value?.result?.message != null ? <p>{test.value?.result?.message}</p> : "No result yet"}</td>

                                  </tr>


                              ))
                              )})


                      }

                      </tbody>
                  </table>

              </div>
          </div>
      </div>







  );

}
